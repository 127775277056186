
import Link from "next/link";
import useMarketplaceChain from "../../../hooks/useMarketplaceChain";
import {Box, Flex,Text} from "../../primitives";
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
    ComponentPropsWithoutRef,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useRouter } from "next/router";
import supportedChains from 'utils/chains'
import {
    useTrendingCollections,
    useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import {
    CollectionsSortingOption,
} from 'components/common/CollectionsTimeDropdown'
import {
    MintsSortingOption,
} from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import BannerImg from "../../../public/index/hero-bg-habbox-promo-xl.png"
import Collectibles from "../../../public/index/collectibles.png"
import Image from "next/image";

type Props = InferGetServerSidePropsType<typeof getServerSideProps>


const Banner:NextPage = ()=> {
    const router = useRouter()
    const { routePrefix } = useMarketplaceChain()
    // not sure if there is a better way to fix this
    const { theme: nextTheme } = useTheme()
    const [theme, setTheme] = useState<string | null>(null)
    useEffect(() => {
        if (nextTheme) {
            setTheme(nextTheme)
        }
    }, [nextTheme])

    const isSSR = typeof window === 'undefined'
    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    const [tab, setTab] = useState<TabValue>('collections')
    const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

    const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')

    let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
        limit: 20,
        period: sortByPeriod,
        type: 'any',
    }

    const { chain, switchCurrentChain } = useContext(ChainContext)

    useEffect(() => {
        if (router.query.chain) {
            let chainIndex: number | undefined
            for (let i = 0; i < supportedChains.length; i++) {
                if (supportedChains[i].routePrefix == router.query.chain) {
                    chainIndex = supportedChains[i].id
                }
            }
            if (chainIndex !== -1 && chainIndex) {
                switchCurrentChain(chainIndex)
            }
        }
    }, [router.query])

    const {
        data: trendingCollections,
        isValidating: isTrendingCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'volume',
            period: sortByTime,
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const {
        data: featuredCollections,
        isValidating: isFeaturedCollectionsValidating,
    } = useTrendingCollections(
        {
            limit: 20,
            sortBy: 'sales',
            period: '24h',
        },
        chain.id,
        {
            fallbackData: "",
            keepPreviousData: true,
        }
    )
    const { data: trendingMints, isValidating: isTrendingMintsValidating } =
        useTrendingMints({ ...mintsQuery }, chain.id, {
            fallbackData: "",
            keepPreviousData: true,
        })
    let volumeKey: ComponentPropsWithoutRef<
        typeof CollectionRankingsTable
    >['volumeKey'] = '1day'

    switch (sortByTime) {
        case '30d':
            volumeKey = '30day'
            break
        case '7d':
            volumeKey = '7day'
            break
        case '24h':
            volumeKey = '1day'
            break
    }
    console.log("featuredCollections", featuredCollections)
    // @ts-ignore
    return (
        <>
            <Box css={{
                width: "100%",
                backgroundImage: `url(${BannerImg.src})`,
                backgroundSize:"cover",
                backgroundRepeat:"no-repeat",
                backgroundPosition:"80% bottom",
                overflow:"hidden"

            }}>
                <Box css={{
                    maxWidth:"1200px",
                    margin:"0 auto",
                    marginTop:"120px"
                }}>

                    <Flex  justify="between"
                           align="center"
                           direction="column"
                           css={{
                               gap: 24,
                               mb: '$4',
                               px:"$4",
                               "@md": {
                                   flexDirection: 'column',
                                   alignItems: 'start',
                                   justifyContent:"center"
                               },
                           }}>
                        <Box css={{
                            width:"100%",
                        }}>
                            <Text style="h2" css={{
                                color:"$red3"
                            }}>
                                HABBO COLLECTIBLES
                            </Text>

                        </Box>
                        <Box css={{
                            gap: 24,
                            mb: '$4',
                            width:"100%",
                            "@md":{

                                width:"50%"
                            }
                        }}>
                            <Text style="h4" css={{
                                color:"$red3"
                            }}>
                                THE NEW WAY TO PLAY HABBO. BUILD YOUR COLLECTION, GAIN XP AND INCREASE YOUR COLLECTOR LEVEL. THE HIGHER YOU GO, THE GREATER YOUR CHANCES OF HIGH VALUE REWARDS.
                            </Text>
                        </Box>
                        <Box css={{
                            gap: 24,
                            mb: '$4',
                            width:"100%",
                            "@md":{

                                width:"40%"
                            }

                        }}>
                            <Text style="body1"  css={{
                                color:"$red3"
                            }} >
                                ALL THE INFORMATION YOU NEED IS RIGHT HERE,
                                JOIN THE COLLECTORS GUILD
                                AND START YOUR HABBO COLLECTIBLES JOURNEY TODAY.
                            </Text>
                        </Box>
                        <Box css={{
                            gap: 24,
                            mb: '$4',
                            width:"100%",
                            "@md":{

                                width:"40%"
                            }
                        }}>
                            <Text style="h5" css={{
                                color:"$red3"
                            }}>
                                VISIT THE WEB SHOP TO PURCHASE SILVER
                                AND COME JOIN THE FUN!
                            </Text>
                        </Box>
                        <Flex direction="column" css={{
                            gap: 24,
                            mb: '$4',
                            width:"100%",
                            margin:"50px 0",
                            "@md":{
                                width:"40%",
                                flexDirection:"row"
                            }
                        }}>
                            <Link href={`/${routePrefix}/collections/trending}`} style={{
                                backgroundColor: "#00813e",
                                padding: "20px",
                                border:"2px solid #b9f373"
                            }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>COLLECTOR’S GUILD</span>
                            </Link>
                            <Link href={`/${routePrefix}/collection/${featuredCollections && featuredCollections[0].id}`} style={{
                                backgroundColor: "#1f6ea2",
                                padding: "20px",
                                marginLeft: "30px",
                                border:"2px solid #5ec7fc"
                            }}>
                        <span style={{
                            color: "#FFFFFF",
                            fontSize: '20px',
                        }}>WEB SHOP</span>
                            </Link>
                        </Flex>
                        <Box css={{
                            gap: 24,
                            mb: '$4',
                            width:"100%",
                            "@md":{
                                width:"40%"
                            }
                        }}>
                           <Flex align="center" justify="center">
                               <Image src={Collectibles} alt="" width={300}/>
                           </Flex>
                        </Box>
                    </Flex>
                </Box>
            </Box>
        </>
    )
}

type TrendingCollectionsSchema =
    paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
    paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
    ssr: {
        trendingMints: TrendingMintsSchema
        trendingCollections: TrendingCollectionsSchema
        featuredCollections: TrendingCollectionsSchema
    }
}> = async ({ params, res }) => {
    const chainPrefix = params?.chain || ''
    const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
    // init BaseApi
    // supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
    // DefaultChain
    // console.log("请求的路劲",reservoirBaseUrl)
    const headers: RequestInit = {
        headers: {
            'x-api-key': process.env.RESERVOIR_API_KEY || '',
        },
    }
    let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'volume',
        }

    const trendingCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        trendingCollectionsQuery,
        headers
    )
    let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            sortBy: 'sales',
        }

    const featuredCollectionsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending/v1`,
        featuredCollectionQuery,
        headers
    )

    let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
        {
            period: '24h',
            limit: 20,
            type: 'any',
        }

    const trendingMintsPromise = fetcher(
        `${reservoirBaseUrl}/collections/trending-mints/v1`,
        trendingMintsQuery,
        headers
    )

    const promises = await Promise.allSettled([
        trendingCollectionsPromise,
        featuredCollectionsPromise,
        trendingMintsPromise,
    ]).catch((e) => {
        console.error(e)
    })

    const trendingCollections: Props['ssr']['trendingCollections'] =
        promises?.[0].status === 'fulfilled' && promises[0].value.data
            ? (promises[0].value.data as Props['ssr']['trendingCollections'])
            : {}
    const featuredCollections: Props['ssr']['featuredCollections'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['featuredCollections'])
            : {}

    const trendingMints: Props['ssr']['trendingMints'] =
        promises?.[1].status === 'fulfilled' && promises[1].value.data
            ? (promises[1].value.data as Props['ssr']['trendingMints'])
            : {}

    res.setHeader(
        'Cache-Control',
        'public, s-maxage=120, stale-while-revalidate=180'
    )
    return {
        props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
    }
}
export default Banner